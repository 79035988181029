h1,
h2,
h4,
h5,
h6 {
	color: inherit;
}

h3 {
	display: block;
	font-size: 1.17em;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: 0;
	margin-right: 0;
	font-weight: bold;
	color: inherit;
}
